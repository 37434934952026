import api2 from './api-v2';

const get = async (filter) => {
    const client = await api2.getClient();
    const response = await client.get('api-key', { params: filter });
    return api2.handleSuccess(response);
};

const create = async ({ payload }) => {
    const client = await api2.getClient();
    const response = await client.post('api-key', payload);
    return api2.handleSuccess(response);
};

const remove = async (id) => {
    const client = await api2.getClient();
    const response = await client.delete(`api-key/${id}`);
    return api2.handleSuccess(response);
};

export default {
    get,
    create,
    remove,
};
